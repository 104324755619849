import Humanize from 'humanize-plus'
import { FC, useCallback, useState } from 'react'
import { Button, Col, Modal, Radio, Row } from 'react-bootstrap'

import renderPreview from 'components/reimbursements/receipt-preview'
import { Reimbursement } from 'components/reimbursements/type'
// @ts-ignore
import { reimbursementQuery } from 'components/reimbursements/type?query'
import Money from 'components/utilities/money'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import useFetchRequiredActions from 'hooks/useFetchRequiredActions'
import { closeModal, openModal } from 'hooks/useModal'
import Status from 'pages/Member/Reimbursements/details-reimbursement-modal/_Status'
import { acceptReimbursement, Request } from 'thunks/reimbursements/accept'
import formatMoney from 'utils/format-money'
import notify from 'utils/notify'

type Props = {
  id?: number
  organizationId: number
  reimbursement: Reimbursement
  onSuccess: (data: Reimbursement) => void
}

const ReviewProposedReimbursementModal: FC<Props> = ({ organizationId, reimbursement, onSuccess }) => {
  const [method, setMethod] = useState(organizationId === 1 ? 'cash' : reimbursement.method)

  const fetch = useFetchRequiredActions()

  const [accept, { timer }] = useAPI<Reimbursement, Request>(acceptReimbursement)
  const handleAccept = useCallback(async () => {
    const [updated] = await accept({
      id: reimbursement.id,
      query: reimbursementQuery,
      reimbursement: {
        method: organizationId === 1 ? 'cash' : method,
      },
    })

    notify(`You've accepted the ${formatMoney(updated.amount)} reimbursement.`)
    onSuccess(updated)
    fetch()
    closeModal()
  }, [accept, reimbursement.id, organizationId, method, onSuccess, fetch])

  if (reimbursement.status !== 'proposed') {
    closeModal()
    return <></>
  }

  return (
    <Modal show bsSize="lg" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Review reimbursement proposal</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={6}>{renderPreview(reimbursement.document)}</Col>
          <Col sm={6}>
            <Status reimbursement={reimbursement} />

            <dl className="dl-vertical m-t-1">
              <dt>Description</dt>
              <dd>{reimbursement.description}</dd>

              <hr className="spacer-sm" />

              <dt>Amount</dt>
              <dd>
                <Money amount={reimbursement.amount} />
              </dd>

              <hr className="spacer-sm" />

              <dt>Reimbursement</dt>
              <dd>
                <Radio onChange={() => setMethod('credit')} checked={method === 'credit'}>
                  Credit my GCM balance
                </Radio>
                <Radio onChange={() => setMethod('cash')} checked={method === 'cash'}>
                  Cash or check re-payment
                </Radio>
              </dd>
            </dl>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button disabled={timer.isLoading} bsStyle="success" onClick={handleAccept} className="m-l-1">
          Accept
        </Button>
        <Button bsStyle="danger" onClick={openModal('RejectReimbursement', String(reimbursement.id))}>
          Reject &amp; respond
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('ReviewReimbursement', ReviewProposedReimbursementModal)
