import cx from 'classnames'
import { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { Checkbox, Col, Row } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form'

import SubmitButton from 'components/form/button'
import ConnectedAddress from 'components/form/connected-address'
import ConnectedField from 'components/form/connected-field'
import { UploadUserAvatarModal } from 'components/user'
import { Avatar, FaButton, Loading } from 'components/utilities'
import LegacyContext from 'contexts/legacy'
import useCustomFields from 'hooks/api/custom-fields/useCustomFields'
import { useAPIFormHandler } from 'hooks/useAPIFormHandler'
import { openModal } from 'hooks/useModal'
import { CustomField } from 'thunks/custom-fields/list'
import { Request, updateMember } from 'thunks/members/update'
import { Address } from 'types/address'
import { MemberStatus, MemberTypes } from 'types/member'
import { Role } from 'types/user'
import notify, { notifyError } from 'utils/notify'

// @ts-ignore
import { memberQuery } from './type?query'
import useProfileMember from './useProfileMember'

type BaseMember = {
  id: number
  alternate_email: string
  graduation_year: string | undefined
  status: MemberStatus
  no_physical_bills: boolean
  custom_fields: Record<string, string>
  is_admin: boolean
  created_at: string
  organization: {
    id: number
  }
  user: {
    id: number
    first_name: string
    last_name: string
    email: string
    phone_number: string
    avatar?: {
      id: number
      public_url: string
    }
    address?: Address
  }
}

type Props<T extends BaseMember> = {
  memberId: number
  memberTypes?: MemberTypes
  query?: string
  additionalFields?: ReactNode
  onUpdate: (member: T) => void
}

function MemberProfile<T extends BaseMember = BaseMember>({
  memberId,
  memberTypes,
  query,
  additionalFields,
  onUpdate,
}: Props<T>) {
  const { user: authUser } = useContext(LegacyContext)

  const [member, setMember] = useProfileMember<T>(memberId, query ?? memberQuery)

  const [handleSubmit, { form, timer }] = useAPIFormHandler<BaseMember, T, Request>({
    thunk: updateMember,
    model: 'member',
    request: data => ({
      query: query ?? memberQuery,
      id: memberId,
      member: {
        ...data,
        graduation_year: data.graduation_year ? parseInt(data.graduation_year) : null,
      },
    }),
    onSuccess: member => {
      onUpdate(member)
      setMember(member)

      notify('The member profile has been updated!')
    },
    onError: err => {
      console.error('[UPDATE MEMBER PROFILE ERROR]', err)
      notifyError((err as any)?.message || 'There was an error updating the member profile. Please try again.')
    },
  })

  useEffect(() => {
    if (!member) return

    form.reset({
      ...member,
      alternate_email: member.alternate_email ?? '',
      graduation_year: member.graduation_year ? member.graduation_year.toString() : '',
      user: {
        ...member.user,
        phone_number: member.user.phone_number ?? '',
      },
    })
  }, [form, member])

  const isCustomFieldReadOnly = useCallback(
    (cf: CustomField) => authUser && member && _isCustomFieldReadOnly(authUser.role, authUser.id, member.user.id, cf),
    [authUser, member]
  )
  const unsorted = useCustomFields('Organization', member?.organization.id)
  const customFields = useMemo(
    () => _.sortBy(unsorted, cf => [isCustomFieldReadOnly(cf), _.lowerCase(cf.name)]),
    [unsorted, isCustomFieldReadOnly]
  )

  const handleAvatar = useCallback(
    ({ avatar }: Pick<BaseMember['user'], 'avatar'>) => {
      if (!member) return

      const m = {
        ...member,
        user: {
          ...member.user,
          avatar,
        },
      }

      setMember(m)
      onUpdate(m)
    },
    [member, setMember, onUpdate]
  )

  if (!customFields || !member) return <Loading />
  if (!authUser) return <>No authenticated user</>

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <ConnectedField name="-" label="Photo">
          <Avatar
            key={member.user.avatar?.id}
            avatar={member.user.avatar}
            className={cx('avatar', 'd-block', {
              'c-pointer': _.includes(['member', 'admin'], authUser.role),
            })}
            onClick={openModal('UploadAvatar')}
          />

          {['member', 'admin'].includes(authUser.role) && (
            <FaButton icon="cloud-upload" bsSize="sm" onClick={openModal('UploadAvatar')} className="m-t-1">
              Upload a pic
            </FaButton>
          )}
        </ConnectedField>

        <hr />

        {authUser.role === 'root' && (
          <ConnectedField label="Admin" name="is_admin">
            {({ value, onChange }) => (
              <Checkbox
                checked={value}
                onChange={e => {
                  onChange((e.target as HTMLInputElement).checked)
                }}
              >
                user is a chapter admin
              </Checkbox>
            )}
          </ConnectedField>
        )}

        {authUser.role === 'root' ? (
          <ConnectedField label="Name" name="_">
            <Row>
              <Col md={5}>
                <input
                  type="text"
                  placeholder="First name"
                  className="form-control"
                  {...form.register('user.first_name')}
                />
              </Col>
              <Col md={7}>
                <input
                  type="text"
                  placeholder="Last name"
                  className="form-control"
                  {...form.register('user.last_name')}
                />
              </Col>
            </Row>
          </ConnectedField>
        ) : (
          <ConnectedField
            label="Name"
            name="_"
            value={`${member.user.first_name} ${member.user.last_name}`}
            disabled
            readOnly
          />
        )}

        <ConnectedField name="user.email" label="Email" />

        <ConnectedField name="alternate_email" label="Alternate email" />

        <ConnectedField name="user.phone_number" label="Phone number" mask="(999) 999-9999" />

        <ConnectedAddress label="Campus address" name="user.address" />

        <ConnectedField name="graduation_year" label="Graduation year" mask="9999" />

        {memberTypes && (
          <ConnectedField label="Member status" name="status">
            {({ value, onChange }) => (
              <select value={value} onChange={e => onChange(e.target.value)} className="form-control w-56">
                <option value="pledge">{memberTypes.pledge}</option>
                <option value="member">{memberTypes.member}</option>
              </select>
            )}
          </ConnectedField>
        )}

        {['member', 'admin', 'root'].includes(authUser.role) && (
          <ConnectedField label="Green delivery" name="no_physical_bills">
            {({ value, onChange }) => (
              <Checkbox
                checked={value}
                onChange={e => {
                  onChange((e.target as HTMLInputElement).checked)
                }}
              >
                Only email bills will be sent, no bills will be snail mailed
              </Checkbox>
            )}
          </ConnectedField>
        )}

        {additionalFields}

        <hr />

        {customFields.map(cf => (
          <ConnectedField
            key={cf.id}
            name={`custom_fields.${cf.slug}`}
            label={cf.name}
            disabled={isCustomFieldReadOnly(cf)}
            readOnly={isCustomFieldReadOnly(cf)}
          />
        ))}

        {customFields.length > 0 && <hr />}

        <Row>
          <Col mdPush={3} md={9}>
            <SubmitButton timer={timer}>Update profile</SubmitButton>
          </Col>
        </Row>

        {member && <UploadUserAvatarModal userId={member.user.id} onSuccess={handleAvatar} />}
      </form>
    </FormProvider>
  )
}

export default MemberProfile

function _isCustomFieldReadOnly(role: Role, userId: number, memberUserId: number, customField: CustomField): boolean {
  // Federation users can edit all fields
  if (role === 'federation') {
    return false
  }

  const isAdminAndEditingThemself = role === 'admin' && userId === memberUserId
  if (isAdminAndEditingThemself) {
    return customField.is_readonly && customField.owner_type === 'Federation'
  }

  // Members respect the editable attribute
  if (role === 'member') {
    return customField.is_readonly
  }

  // Admins can edit chapter level fields, not federation level fields
  if (role === 'admin') {
    return customField.owner_type === 'Federation'
  }

  // Roots can edit everything
  if (role === 'root') {
    return false
  }

  // Who is trying to edit the member profile?
  throw new Error(`Unknown role trying to edit member profile: ${role}`)
}
